import { forwardAIIntegrationRoutine, forwardAIErrorRoutine, forwardAIGetters } from '@/store/modules/forwardAI/routines'
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'

export default {
  data() {
    return {
      forwardAIIntegrationTimerId: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION,
      forwardAIError: forwardAIGetters.FORWARD_AI_ERROR
    })
  },
  methods: {
    ...mapActions({
      getForwardAIIntegration: forwardAIIntegrationRoutine.TRIGGER,
      setForwardAIErrorRoutine: forwardAIErrorRoutine.TRIGGER
    }),
    async requestForwardAIIntegration() {
      try {
        await this.getForwardAIIntegration(this.companyId) // IntegrationStarted, IntegrationInProgress, Synced

        if (!this.forwardAIIntegration?.status || (this.forwardAIIntegration?.status === 'connected')) {
          return
        }

        return new Promise(resolve => {
          this.forwardAIIntegrationTimerId = setTimeout(() => {
            resolve(this.getForwardAIIntegration(this.companyId))
          }, 1000)
        })
      } catch (error) {
        this.setForwardAIErrorRoutine(error?.response.status === 500)
        this.isLoading = false
      }
    }
  }
}
