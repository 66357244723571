<template>
  <button class="btn disabled" :disabled="loading" v-if="loading">
    {{ btnLabel }}
    <spinner />
  </button>
  <button :class="classNames" :disabled="loading" v-promise-btn @click="truelayerRedirect" v-else>
    <slot>
      <i v-if="icon" class="material-icons">cable</i>
      {{ btnLabel }}
    </slot>
  </button>
</template>

<script>
import { getTruelayerLink } from '@/api/truelayer'
import { LSKeys } from '@/utils/constants'
import { toastDefaultOptions } from '../../config/vue-toast'

export default {
  name: 'BankIntegrationBtn',
  props: {
    classNames: {
      default: 'btn btn-primary btn-border'
    },
    btnLabel: {
      type: String,
      default: 'Link bank account'
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean
    }
  },
  methods: {
    async truelayerRedirect() {
      let redirectLink
      try {
        redirectLink = await getTruelayerLink().then(res => res.data)
      } catch (e) {
        console.error(e)
        return
      }
      try {
        if (this.$route) {
          localStorage.setItem(LSKeys.truelayerIntegrationPrevRoute, JSON.stringify({ name: this.$route.name, params: this.$route.params }))
        }
      } catch (e) {
        console.error('No access to Local Storage')
      }
      if (!redirectLink) {
        this.$toasted.show('Bank Account Integration. No redirect link!.', { ...toastDefaultOptions, type: 'error', icon: 'error' })
        return
      }
      window.location.href = redirectLink
    }
  }
}
</script>

<style lang="scss" scoped>
.btn.disabled {
  padding: 0;
  font-size: 12px;
  width: 170px;
  margin-right: 8px;
}

</style>
