<template>
  <button v-if="loading" class="btn disabled">
    {{ btnLabel }}
    <spinner :size="40" :loader="true" color />
  </button>
  <button v-else :class="classNames" v-promise-btn @click="forwardAiIntegration">
    <i v-if="icon" class="material-icons">cable</i>
    {{ btnLabel }}
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companySelectRoutine } from '@/store/modules/company/routines'
import { forwardAIIntegrationRoutine, forwardAIGetters } from '@/store/modules/forwardAI/routines'
import { toastDefaultOptions } from '@/config/vue-toast'
import { LSKeys } from '@/utils/constants'
import { accountingOnboard } from '@/api/forwardAI'
import { getCompany } from '@/api/company'
import { authGetters } from '@/store/modules/auth/routines'
import Spinner from '@/components/Spinner'

export default {
  name: 'ForwardAIIntegrationBtn',
  components: {
    Spinner
  },
  data() {
    return {
      status: null,
      connectionUrl: null,
      forwardAIIntegrationTimerId: null,
      limit: 5
    }
  },
  props: {
    classNames: {
      default: 'btn btn-primary btn-border'
    },
    companyId: {
      required: true
    },
    btnLabel: {
      type: String,
      default: 'Link accountancy software'
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION,
      user: authGetters.USER
    })
  },
  methods: {
    ...mapActions({
      selectCompany: companySelectRoutine.TRIGGER,
      getForwardAIIntegration: forwardAIIntegrationRoutine.TRIGGER
    }),
    async forwardAiIntegration() {
      if (this.forwardAIIntegration?.status === 'notIntegrated') {
        try {
          const {
            data: { companyName }
          } = await getCompany(this.companyId)
          const companyEmail = this.user?.email
          const payload = { companyName, companyEmail }
          await accountingOnboard(this.companyId, payload) // we should call onboarding once only

          while (this.limit > 0 && this.forwardAIIntegration.status === 'notIntegrated') {
            // then we need to wait status change
            await new Promise(resolve => setTimeout(resolve, 1000))
            await this.getForwardAIIntegration(this.companyId)
            this.limit--
          }
        } catch (error) {
          this.$toasted.show('forwardAI integration error', { ...toastDefaultOptions, type: 'error', icon: 'error' })
        }
      }

      if (this.forwardAIIntegration?.status === 'pending') {
        const {
          data: { status, connectionUrl }
        } = await this.getForwardAIIntegration(this.companyId)
        this.status = status
        this.connectionUrl = connectionUrl
        const { name, params } = this.$route
        localStorage.setItem(LSKeys.forwardAIIntegrationPrevRoute, JSON.stringify({ name, params }))
        window.open(`${this.connectionUrl}`)
        return
      }

      if (this.forwardAIIntegration?.status === 'onboardingFailed') {
        this.$toasted.show('There was an error processing your request', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }

      return new Promise(resolve => {
        this.forwardAIIntegrationTimerId = setTimeout(() => {
          resolve(this.getForwardAIIntegration(this.companyId))
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn.disabled {
  padding: 0;
  width: 250px;
  font-size: 12px;
  /deep/.spinner {
    top: -2px;
  }
}

</style>
