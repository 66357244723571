<template src="./path.html"></template>
<style src="./path.scss" lang="scss" scoped></style>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import Integrations from '@/components/integrations'

export default {
  name: 'Path',
  components: {
    Integrations
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      companyState: companyGetters.COMPANY_STATE
    })
  }
}
</script>
