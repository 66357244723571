<template v-if="$config.whitelabel.features.linkBankAccount || $config.whitelabel.features.linkAccountancy">
  <div class="ui flex integration-links">
    <BankIntegrationBtnTrueLayer
      v-if="$config.whitelabel.features.linkBankAccount && $route.name !== 'path' && !completed.isBankMandateStatusComplete"
      @click.native="sendConnBankClickToAmplitude()"
      :loading="linkBankIsLoading"
      :classNames="'ui button btn-primary circular'"
    />
    <ForwardAIIntegrationBtn
      v-if="$config.whitelabel.features.linkAccountancy && ($route.name === 'profile-integrations' || !completed.isForwardAIIntegrationComplete)"
      @click.native="sendConnAccSysClickToAmplitude()"
      :companyId="companyId"
      :classNames="'ui button btn-primary circular'"
    />
    <modal v-if="$config.whitelabel.features.linkBankAccount" name="editBankModal" height="auto" :scrollable="true" :width="560" @closed="editBankModalCloseHandler">
      <div v-if="selectedBankMandate">
        <div class="title text-center m-b-30">Do you want to unlink bank from your account?</div>
        <div class="bank-mandate m-b-40">
          <span class="bank-name bg-primary text-white bank-mandate">{{ selectedBankMandate.bankName }}</span>
          <button type="button" class="btn btn-sm btn-warning m-l-25" @click="unlinkBank" :disabled="selectedBankMandate.status !== 'ProcessingCompleted'" v-promise-btn>
            Unlink
          </button>
        </div>
        <div class="text-center">
          <button class="btn btn-primary" @click="$modal.hide('editBankModal')">Close</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { authGetters } from '@/store/modules/auth/routines'
import { deleteBankMandate } from '@/api/bankMandate'
import { bankMandateListRoutine, bankGetters } from '@/store/modules/bank/routines'
import BankIntegrationBtnTrueLayer from '@/components/integrations/BankIntegrationBtnTrueLayer'
import { amplitudeEvents } from '@/utils/constants'
import { toastDefaultOptions } from '../../config/vue-toast'
import { forwardAIIntegrationRoutine, forwardAIGetters } from '@/store/modules/forwardAI/routines'
import ForwardAIIntegrationBtn from '@/components/integrations/ForwardAIIntegrationBtn'
import accountingIntegration from '@/mixins/accountingIntegration'

export default {
  name: 'Integrations',
  components: {
    BankIntegrationBtnTrueLayer,
    ForwardAIIntegrationBtn
  },
  mixins: [accountingIntegration],
  data() {
    return {
      bankProvider: process.env.VUE_APP_BANK_DEFAULT_GATEWAY,
      accountingSystemProvider: process.env.VUE_APP_ACCOUNTING_SYSTEM_DEFAULT_GATEWAY,
      selectedBankMandateId: null,
      bankMandateTimer: null,
      forwardAIIntegrationTimerId: null,
      forwardAIRedirectLink: null
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER,
      companyId: companyGetters.COMPANY_ID,
      bankMandateList: bankGetters.BANK_MANDATE_LIST,
      bankMandateStatus: bankGetters.BANK_MANDATE_STATUS,
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION
    }),
    linkBankIsLoading() {
      return this.bankMandateStatus && this.bankMandateStatus !== 'ProcessingCompleted'
    },
    isRootLoading() {
      return this.companyId ? this.forwardAIIntegration === null || this.getBankMandateList === null : false
    },
    completed() {
      const { status: forwardAIStatus = null } = this.forwardAIIntegration ? this.forwardAIIntegration : {}
      return {
        isForwardAIIntegrationComplete: forwardAIStatus === 'connected',
        isBankMandateStatusComplete: this.bankMandateStatus === 'ProcessingCompleted'
      }
    },
    hasCompletedIntegrations() {
      const complete = !Object.values(this.completed).includes(false)
      if (complete) this.$emit('complete')
      return complete
    },
    selectedBankMandate() {
      if (!this.selectedBankMandateId || !this.bankMandateList || !this.bankMandateList.length) return null
      return this.bankMandateList.find(bank => bank.bankMandateId === this.selectedBankMandateId)
    }
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER,
      getForwardAIIntegration: forwardAIIntegrationRoutine.TRIGGER
    }),
    init() {
      if (this.companyId) {
        return Promise.all([
          this.$route.name === 'dashboard' ? null : this.requestForwardAIIntegration(),
          this.$route.name === 'dashboard' || this.$route.name === 'path' ? null : this.requestBankMandate()
        ])
      }
    },
    async requestBankMandate() {
      try {
        await this.getBankMandateList(this.companyId) // FetchingData, FetchingDataCompleted, ProcessingCompleted, Failed
      } catch (err) {
        this.$toasted.show('Bank integration state is currently not available', { ...toastDefaultOptions, type: 'error', icon: 'error' })
        return
      }
      if (!this.user || !this.bankMandateStatus || this.bankMandateStatus === 'ProcessingCompleted' || this.bankMandateStatus === 'Failed') {
        return
      }
      return new Promise(resolve => {
        this.bankMandateTimerId = setTimeout(() => {
          resolve(this.requestBankMandate())
        }, 2000)
      })
    },
    unlinkBank() {
      return deleteBankMandate(this.selectedBankMandate.bankMandateId)
        .then(this.requestBankMandate)
        .then(() => {
          this.$modal.hide('editBankModal')
        })
        .catch(e => {
          console.log(e)
        })
    },
    showBankModal(bankMandate) {
      this.selectedBankMandateId = bankMandate.bankMandateId
      this.$modal.show('editBankModal')
    },
    editBankModalCloseHandler() {
      this.selectedBankMandateId = null
    },
    cleanUp() {
      clearTimeout(this.forwardAIIntegrationTimerId)
      clearTimeout(this.bankMandateTimer)
    },
    sendConnBankClickToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.fundingOnboarding.INTEG_BANK_START)
    },
    sendConnAccSysClickToAmplitude() {
      /** Amplitude Analytics */
      this.$ma.trackEvent(amplitudeEvents.fundingOnboarding.INTEG_ACC_START)
    }
  },
  beforeMount() {
    this.init()
  },
  watch: {
    companyId() {
      this.init()
    },
    bankMandateList(newData) {
      clearTimeout(this.bankMandateTimer)
      if (this.$route.name === 'dashboard' || !this.bankMandateStatus || this.bankMandateStatus === 'ProcessingCompleted') return
      this.bankMandateTimer = setTimeout(this.fillBankMandateList, 20000)
    }
  },
  beforeDestroy() {
    this.cleanUp()
  }
}
</script>

<style lang="scss" scoped>
.integration-links {
  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }

  /deep/.spinner {
    top: -2px;
  }
}
.btn-list {
  margin-right: 10px;
  /deep/ {
    .btn {
      white-space: nowrap;
      .fa-icon {
        margin-left: 16px;
      }
    }
  }
}
.completed-integrations-list {
  list-style: none;
  padding-left: 0;
}
.bank-mandate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bank-name {
    padding: 8px 26px;
  }
  .bank-status {
    flex-grow: 1;
  }
  .bank-mandate-btn {
    margin: 5px;
  }
}
.v--modal {
  .bank-mandate {
    justify-content: flex-start;
  }
}
.integrations-buttons-container {
  display: flex;
}
/deep/button.btn {
  font-size: 12px;
}
</style>
