import { render, staticRenderFns } from "./index.vue?vue&type=template&id=185f0723&scoped=true&v-if=%24config.whitelabel.features.linkBankAccount%20%7C%7C%20%24config.whitelabel.features.linkAccountancy&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=185f0723&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185f0723",
  null
  
)

export default component.exports